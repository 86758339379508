import { lazy } from 'react';
import { createRoot } from 'react-dom/client';

import '@bluecodecom/fonts';

import { usePageShowReload } from '@bluecodecom/hooks';

import './i18n';

const App = lazy(() => import('./app'));

const Main = () => {
  usePageShowReload();

  return <App />;
};

createRoot(document.getElementById('root') as HTMLElement).render(<Main />);
