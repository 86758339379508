import { useCallback, useEffect, useRef, useState } from 'react';

import { UseLongPressProps } from './useLongPress.types';

const useLongPress = ({ delay = 200 }: UseLongPressProps = {}) => {
  const [isLongPress, setIsLongPress] = useState(false);

  const timerRef = useRef(0);
  const isLongPressRef = useRef(false);

  const startPressTimer = useCallback(() => {
    isLongPressRef.current = false;

    timerRef.current = window.setTimeout(() => {
      isLongPressRef.current = true;
      setIsLongPress(true);
    }, delay);
  }, [delay]);

  const handleOnClick = useCallback((e: MouseEvent) => {
    if (!isLongPressRef.current) {
      return;
    }

    e.stopPropagation();
  }, []);

  const handleOnMouseDown = useCallback(() => {
    startPressTimer();
  }, [startPressTimer]);

  const handleOnMouseUp = useCallback(() => {
    clearTimeout(timerRef.current);
  }, []);

  const handleOnTouchStart = useCallback(() => {
    startPressTimer();
  }, [startPressTimer]);

  const handleOnTouchEnd = useCallback(() => {
    if (!isLongPressRef.current) {
      clearTimeout(timerRef.current);
    }

    setIsLongPress(false);
  }, []);

  const handleContextMenu = useCallback((e: MouseEvent) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    document.addEventListener('touchend', handleOnTouchEnd);
    document.addEventListener('touchstart', handleOnTouchStart);
    document.addEventListener('mouseup', handleOnMouseUp);
    document.addEventListener('mousedown', handleOnMouseDown);
    document.addEventListener('click', handleOnClick);
    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('touchend', handleOnTouchEnd);
      document.removeEventListener('touchstart', handleOnTouchStart);
      document.removeEventListener('mouseup', handleOnMouseUp);
      document.removeEventListener('mousedown', handleOnMouseDown);
      document.removeEventListener('click', handleOnClick);
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [
    handleOnClick,
    handleOnMouseDown,
    handleOnMouseUp,
    handleOnTouchEnd,
    handleOnTouchStart,
    handleContextMenu,
  ]);

  return { isLongPress };
};

export default useLongPress;
