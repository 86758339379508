let canvas: HTMLCanvasElement | undefined;

const getTextWidthFromCanvas = (text: string, font: string) => {
  // if given, use cached canvas for better performance
  // else, create new canvas
  const c = canvas || (canvas = document.createElement('canvas'));
  const context = c.getContext('2d');

  if (!context) {
    return 0;
  }

  context.font = font;

  return context.measureText(text).width;
};

export default getTextWidthFromCanvas;
