import { useEffect, useMemo, useState } from 'react';

import qrCodeGenerator from 'qrcode-generator';

import {
  Coordinates,
  CornerRadii,
  EyeColor,
  QrCodeImageProps,
} from './useQrCodeImage.types';

function utf16to8(str: string): string {
  let out = '';
  let i: number, c: number;
  const len: number = str.length;
  for (i = 0; i < len; i++) {
    c = str.charCodeAt(i);
    if (c >= 0x0001 && c <= 0x007f) {
      out += str.charAt(i);
    } else if (c > 0x07ff) {
      out += String.fromCharCode(0xe0 | ((c >> 12) & 0x0f));
      out += String.fromCharCode(0x80 | ((c >> 6) & 0x3f));
      out += String.fromCharCode(0x80 | ((c >> 0) & 0x3f));
    } else {
      out += String.fromCharCode(0xc0 | ((c >> 6) & 0x1f));
      out += String.fromCharCode(0x80 | ((c >> 0) & 0x3f));
    }
  }
  return out;
}

/**
 * Draw a rounded square in the canvas
 */
function drawRoundedSquare(
  lineWidth: number,
  x: number,
  y: number,
  size: number,
  color: string,
  radii: number | number[],
  fill: boolean,
  ctx: CanvasRenderingContext2D,
) {
  ctx.lineWidth = lineWidth;
  ctx.fillStyle = color;
  ctx.strokeStyle = color;

  // Adjust coordinates so that the outside of the stroke is aligned to the edges
  y += lineWidth / 2;
  x += lineWidth / 2;
  size -= lineWidth;

  if (!Array.isArray(radii)) {
    radii = [radii, radii, radii, radii];
  }

  // Radius should not be greater than half the size or less than zero
  radii = radii.map((r) => {
    r = Math.min(r, size / 2);
    return r < 0 ? 0 : r;
  });

  const rTopLeft = radii[0] || 0;
  const rTopRight = radii[1] || 0;
  const rBottomRight = radii[2] || 0;
  const rBottomLeft = radii[3] || 0;

  ctx.beginPath();

  ctx.moveTo(x + rTopLeft, y);

  ctx.lineTo(x + size - rTopRight, y);
  if (rTopRight) ctx.quadraticCurveTo(x + size, y, x + size, y + rTopRight);

  ctx.lineTo(x + size, y + size - rBottomRight);
  if (rBottomRight)
    ctx.quadraticCurveTo(x + size, y + size, x + size - rBottomRight, y + size);

  ctx.lineTo(x + rBottomLeft, y + size);
  if (rBottomLeft) ctx.quadraticCurveTo(x, y + size, x, y + size - rBottomLeft);

  ctx.lineTo(x, y + rTopLeft);
  if (rTopLeft) ctx.quadraticCurveTo(x, y, x + rTopLeft, y);

  ctx.closePath();

  ctx.stroke();
  if (fill) {
    ctx.fill();
  }
}

/**
 * Draw a single positional pattern eye.
 */
function drawPositioningPattern(
  ctx: CanvasRenderingContext2D,
  cellSize: number,
  offset: number,
  row: number,
  col: number,
  color: EyeColor,
  radii: CornerRadii = [0, 0, 0, 0],
) {
  const lineWidth = Math.ceil(cellSize);

  let radiiOuter;
  let radiiInner;
  if (typeof radii !== 'number' && !Array.isArray(radii)) {
    radiiOuter = radii.outer || 0;
    radiiInner = radii.inner || 0;
  } else {
    radiiOuter = radii as CornerRadii;
    radiiInner = radiiOuter;
  }

  let colorOuter;
  let colorInner;
  if (typeof color !== 'string') {
    colorOuter = color.outer;
    colorInner = color.inner;
  } else {
    colorOuter = color;
    colorInner = color;
  }

  let y = row * cellSize + offset;
  let x = col * cellSize + offset;
  let size = cellSize * 7;

  // Outer box
  drawRoundedSquare(
    lineWidth,
    x,
    y,
    size,
    colorOuter,
    radiiOuter as number,
    false,
    ctx,
  );

  // Inner box
  size = cellSize * 3;
  y += cellSize * 2;
  x += cellSize * 2;
  drawRoundedSquare(
    lineWidth,
    x,
    y,
    size,
    colorInner,
    radiiInner as number,
    true,
    ctx,
  );
}

/**
 * Is this dot inside a positional pattern zone.
 */
function isInPositionZone(col: number, row: number, zones: Coordinates[]) {
  return zones.some(
    (zone) =>
      row >= zone.row &&
      row <= zone.row + 7 &&
      col >= zone.col &&
      col <= zone.col + 7,
  );
}

function transformPixelLengthIntoNumberOfCells(
  pixelLength: number,
  cellSize: number,
) {
  return pixelLength / cellSize;
}

function isCoordinateInImage(
  col: number,
  row: number,
  dWidthLogo: number,
  dHeightLogo: number,
  dxLogo: number,
  dyLogo: number,
  cellSize: number,
  logoImage: string,
) {
  if (logoImage) {
    const numberOfCellsMargin = 2;
    const firstRowOfLogo = transformPixelLengthIntoNumberOfCells(
      dxLogo,
      cellSize,
    );
    const firstColumnOfLogo = transformPixelLengthIntoNumberOfCells(
      dyLogo,
      cellSize,
    );
    const logoWidthInCells =
      transformPixelLengthIntoNumberOfCells(dWidthLogo, cellSize) - 1;
    const logoHeightInCells =
      transformPixelLengthIntoNumberOfCells(dHeightLogo, cellSize) - 1;

    return (
      row >= firstRowOfLogo - numberOfCellsMargin &&
      row <= firstRowOfLogo + logoWidthInCells + numberOfCellsMargin && // check rows
      col >= firstColumnOfLogo - numberOfCellsMargin &&
      col <= firstColumnOfLogo + logoHeightInCells + numberOfCellsMargin
    ); // check cols
  } else {
    return false;
  }
}

const useQrCodeImage = (props: QrCodeImageProps) => {
  const [image, setImage] = useState('');
  const quietZone = typeof props.quietZone === 'number' ? props.quietZone : 10;

  const s = props.size || 150;
  const value = props.value;
  const size = +s + 2 * +quietZone;
  const ecLevel = props.ecLevel || 'M';
  const bgColor = props.bgColor || '#FFFFFF';
  const fgColor = props.fgColor || '#000000';
  const logoWidth = props.logoWidth || 40;
  const logoHeight = props.logoHeight || 40;
  const removeQrCodeBehindLogo = !!props.removeQrCodeBehindLogo;
  const logoImage = props.logoImage || '';
  const qrStyle = props.qrStyle || 'squares';
  const eyeRadius = useMemo(
    () => props.eyeRadius || [0, 0, 0],
    [props.eyeRadius],
  );
  const eyeColor = props.eyeColor;
  const enableCORS = !!props.enableCORS;
  const logoOpacity = props.logoOpacity || 1;
  const roundedSquareRadius = props.roundedSquareRadius || 3;

  useEffect(() => {
    const qrCode = qrCodeGenerator(0, ecLevel);
    qrCode.addData(utf16to8(value));
    qrCode.make();

    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return;
    }

    const canvasSize = +size + 2 * +quietZone;
    const length = qrCode.getModuleCount();
    const cellSize = size / length;
    const scale = window.devicePixelRatio || 1;
    canvas.height = canvas.width = canvasSize * scale;
    ctx.scale(scale, scale);

    ctx.fillStyle = bgColor;
    ctx.fillRect(0, 0, canvasSize, canvasSize);

    const offset = +quietZone;

    const dWidthLogo = logoWidth || size * 0.2;
    const dHeightLogo = logoHeight || dWidthLogo;
    const dxLogo = (size - dWidthLogo) / 2;
    const dyLogo = (size - dHeightLogo) / 2;

    const positioningZones: Coordinates[] = [
      { row: 0, col: 0 },
      { row: 0, col: length - 7 },
      { row: length - 7, col: 0 },
    ];

    ctx.strokeStyle = fgColor;

    function testN(row: number, col: number) {
      if (row < 0 || col < 0 || row > length - 1 || col > length - 1) {
        return true;
      }

      if (
        isCoordinateInImage(
          row,
          col,
          dWidthLogo,
          dHeightLogo,
          dxLogo,
          dyLogo,
          cellSize,
          logoImage,
        )
      ) {
        return true;
      }

      return !qrCode.isDark(row, col);
    }

    if (qrStyle === 'dots') {
      ctx.fillStyle = fgColor;
      const radius = cellSize / 2;
      for (let row = 0; row < length; row++) {
        for (let col = 0; col < length; col++) {
          if (
            qrCode.isDark(row, col) &&
            !isInPositionZone(row, col, positioningZones) &&
            !(
              removeQrCodeBehindLogo &&
              isCoordinateInImage(
                row,
                col,
                dWidthLogo,
                dHeightLogo,
                dxLogo,
                dyLogo,
                cellSize,
                logoImage,
              )
            )
          ) {
            ctx.beginPath();
            ctx.arc(
              Math.round(col * cellSize) + radius + offset,
              Math.round(row * cellSize) + radius + offset,
              (radius / 100) * 75,
              0,
              2 * Math.PI,
              false,
            );
            ctx.closePath();
            ctx.fill();
          }
        }
      }
    } else if (qrStyle === 'rounded-squares' && ctx.roundRect) {
      for (let row = 0; row < length; row++) {
        for (let col = 0; col < length; col++) {
          if (
            qrCode.isDark(row, col) &&
            !isInPositionZone(row, col, positioningZones) &&
            !(
              removeQrCodeBehindLogo &&
              isCoordinateInImage(
                row,
                col,
                dWidthLogo,
                dHeightLogo,
                dxLogo,
                dyLogo,
                cellSize,
                logoImage,
              )
            )
          ) {
            const n = [
              [
                testN(row - 1, col - 1),
                testN(row - 1, col),
                testN(row - 1, col + 1),
              ],
              [testN(row, col - 1), testN(row, col), testN(row, col + 1)],
              [
                testN(row + 1, col - 1),
                testN(row + 1, col),
                testN(row + 1, col + 1),
              ],
            ];

            const topLeftCorner = n[0][1] && n[1][0] ? roundedSquareRadius : 0;
            const topRightCorner = n[0][1] && n[1][2] ? roundedSquareRadius : 0;
            const bottomLeftCorner =
              n[2][1] && n[1][0] ? roundedSquareRadius : 0;
            const bottomRightCorner =
              n[2][1] && n[1][2] ? roundedSquareRadius : 0;

            ctx.fillStyle = fgColor;
            const w =
              Math.ceil((col + 1) * cellSize) - Math.floor(col * cellSize);
            const h =
              Math.ceil((row + 1) * cellSize) - Math.floor(row * cellSize);
            ctx.beginPath();
            ctx.roundRect(
              Math.round(col * cellSize) + offset,
              Math.round(row * cellSize) + offset,
              w,
              h,
              [
                topLeftCorner,
                topRightCorner,
                bottomRightCorner,
                bottomLeftCorner,
              ],
            );
            ctx.closePath();
            ctx.fill();
          }
        }
      }
    } else {
      for (let row = 0; row < length; row++) {
        for (let col = 0; col < length; col++) {
          if (
            qrCode.isDark(row, col) &&
            !isInPositionZone(row, col, positioningZones) &&
            !(
              removeQrCodeBehindLogo &&
              isCoordinateInImage(
                row,
                col,
                dWidthLogo,
                dHeightLogo,
                dxLogo,
                dyLogo,
                cellSize,
                logoImage,
              )
            )
          ) {
            ctx.fillStyle = fgColor;
            const w =
              Math.ceil((col + 1) * cellSize) - Math.floor(col * cellSize);
            const h =
              Math.ceil((row + 1) * cellSize) - Math.floor(row * cellSize);
            ctx.fillRect(
              Math.round(col * cellSize) + offset,
              Math.round(row * cellSize) + offset,
              w,
              h,
            );
          }
        }
      }
    }

    for (let i = 0; i < 3; i++) {
      const { row, col } = positioningZones[i];

      let radii = eyeRadius;
      let color;

      if (Array.isArray(radii)) {
        radii = radii[i];
      }
      if (typeof radii == 'number') {
        radii = [radii, radii, radii, radii];
      }

      if (!eyeColor) {
        // if not specified, eye color is the same as foreground,
        color = fgColor;
      } else {
        if (Array.isArray(eyeColor)) {
          // if array, we pass the single color
          color = eyeColor[i];
        } else {
          color = eyeColor as EyeColor;
        }
      }

      drawPositioningPattern(
        ctx,
        cellSize,
        offset,
        row,
        col,
        color,
        radii as CornerRadii,
      );

      if (logoImage) {
        const image = new Image();
        if (enableCORS) {
          image.crossOrigin = 'Anonymous';
        }
        image.onload = () => {
          ctx.save();
          ctx.globalAlpha = logoOpacity;
          ctx.drawImage(
            image,
            dxLogo + offset,
            dyLogo + offset,
            dWidthLogo,
            dHeightLogo,
          );
          ctx.restore();
          setImage(canvas.toDataURL('image/png', 1.0));
        };
        image.src = logoImage;
      } else {
        setImage(canvas.toDataURL('image/png', 1.0));
      }
    }
  }, [
    bgColor,
    ecLevel,
    enableCORS,
    eyeColor,
    eyeRadius,
    fgColor,
    logoHeight,
    logoImage,
    logoOpacity,
    logoWidth,
    qrStyle,
    quietZone,
    removeQrCodeBehindLogo,
    roundedSquareRadius,
    size,
    value,
  ]);

  return { image, size };
};

export default useQrCodeImage;
