import { useCallback, useEffect, useRef } from 'react';

const useDelay = (
  value: number,
): [(callback: VoidFunction) => void, VoidFunction] => {
  const ref = useRef(-1);

  const clear = useCallback(() => {
    window.clearTimeout(ref.current);
  }, []);

  const delay = useCallback(
    (callback: VoidFunction) => {
      clear();
      ref.current = window.setTimeout(callback, value);
    },
    [value, clear],
  );

  useEffect(() => clear, [clear]);

  return [delay, clear];
};

export default useDelay;
