import { useMemo } from 'react';

const paddingDiff = 40;

const useLoadingItemsCount = (ref?: HTMLDivElement | null, itemHeight = 64) => {
  const itemsCount = useMemo(() => {
    if (!ref) return 0;

    return Math.abs(Math.floor((ref.offsetHeight - paddingDiff) / itemHeight));
  }, [itemHeight, ref]);

  return itemsCount;
};

export default useLoadingItemsCount;
