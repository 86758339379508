import { useMemo } from 'react';

import { TypoSize } from '@bluecodecom/types';

import getTextWidthFromCanvas from './getTextWidthFromCanvas';
import { UseTypoSizeProps, WidthWithSize } from './useTypoSize.type';

const useTypoSize = ({
  ref,
  text = '',
  max = '5xl',
}: UseTypoSizeProps): TypoSize => {
  const w_9xl = useMemo(
    () => getTextWidthFromCanvas(text, `normal 128px sans-serif`),
    [text],
  );
  const w_8xl = useMemo(
    () => getTextWidthFromCanvas(text, `normal 96px sans-serif`),
    [text],
  );
  const w_6xl = useMemo(
    () => getTextWidthFromCanvas(text, `normal 60px sans-serif`),
    [text],
  );
  const w_5xl = useMemo(
    () => getTextWidthFromCanvas(text, `normal 48px sans-serif`),
    [text],
  );
  const w_4xl = useMemo(
    () => getTextWidthFromCanvas(text, `normal 36px sans-serif`),
    [text],
  );
  const w_3xl = useMemo(
    () => getTextWidthFromCanvas(text, `normal 30px sans-serif`),
    [text],
  );
  const w_2xl = useMemo(
    () => getTextWidthFromCanvas(text, `normal 24px sans-serif`),
    [text],
  );
  const w_xl = useMemo(
    () => getTextWidthFromCanvas(text, `normal 20px sans-serif`),
    [text],
  );
  const w_lg = useMemo(
    () => getTextWidthFromCanvas(text, `normal 18px sans-serif`),
    [text],
  );
  const w_base = useMemo(
    () => getTextWidthFromCanvas(text, `normal 16px sans-serif`),
    [text],
  );
  const w_sm = useMemo(
    () => getTextWidthFromCanvas(text, `normal 14px sans-serif`),
    [text],
  );
  const w_xs = useMemo(
    () => getTextWidthFromCanvas(text, `normal 12px sans-serif`),
    [text],
  );

  const widthWithSize = useMemo(() => {
    const sizes = {
      '9xl': w_9xl,
      '8xl': w_8xl,
      '6xl': w_6xl,
      '5xl': w_5xl,
      '4xl': w_4xl,
      '3xl': w_3xl,
      '2xl': w_2xl,
      xl: w_xl,
      lg: w_lg,
      base: w_base,
      sm: w_sm,
      xs: w_xs,
    };

    const maxSize = sizes[max];

    return (
      [
        { width: w_9xl, size: '9xl' },
        { width: w_8xl, size: '8xl' },
        { width: w_6xl, size: '6xl' },
        { width: w_5xl, size: '5xl' },
        { width: w_4xl, size: '4xl' },
        { width: w_3xl, size: '3xl' },
        { width: w_2xl, size: '2xl' },
        { width: w_xl, size: 'xl' },
        { width: w_lg, size: 'xl' },
      ] as WidthWithSize[]
    ).filter((w) => w.width <= maxSize);
  }, [
    max,
    w_2xl,
    w_3xl,
    w_4xl,
    w_5xl,
    w_6xl,
    w_8xl,
    w_9xl,
    w_base,
    w_lg,
    w_sm,
    w_xl,
    w_xs,
  ]);

  return (
    widthWithSize.find((f) => f.width < (ref?.clientWidth || 0))?.size || 'base'
  );
};

export default useTypoSize;
